<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!--  -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <DxDataGrid id="gvBailiffs" ref="gvBailiffs" v-bind="options" :data-source="bailiffsStore">
              <DxColumn data-field="name" caption="Название" />
              <DxColumn data-field="phone" caption="Телефоны" />
              <DxColumn data-field="address" caption="Адрес ССП">
                <DxFormItem :col-span="2" />
              </DxColumn>

              <DxColumn :visible="false" edit-cell-template="editCourtDecisionTemplates" data-field="courtDecisionTemplatesIds">
                <DxFormItem :col-span="2">
                  <DxLabel location="top" text="Шаблоны заявлений о возбуждении ИП" />
                </DxFormItem>
              </DxColumn>

              <!-- templates -->
              <template #editCourtDecisionTemplates="{ data: rowInfo }">
                <DxTagBox
                  :data-source="templatesStore"
                  :show-selection-controls="true"
                  :value="rowInfo.data.courtDecisionTemplatesIds"
                  :search-enabled="true"
                  @value-changed="(e) => onTemplatesChanged(e, rowInfo)"
                  display-expr="name"
                  value-expr="fileId"
                />
              </template>

              <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="form">
                <DxPopup height="auto" width="800" />
              </DxEditing>
              <DxFilterRow :visible="true" />
              <DxScrolling row-rendering-mode="virtual" />
              <DxPaging :page-size="20" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="[5, 10, 20, 50]"
                :show-page-size-selector="true"
                :show-navigation-buttons="true"
                :show-info="true"
              />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { DxFormItem, DxLabel } from "devextreme-vue/data-grid";

import DxTagBox from "devextreme-vue/tag-box";
import { DxPopup } from "devextreme-vue/data-grid";
import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";

export default {
  components: {
    Layout,
    PageHeader,
    DxPopup,
    DxTagBox,
    DxFormItem,
    DxLabel,
  },

  data() {
    return {
      title: "Службы судебных приставов",
      items: [
        {
          text: "Справочник",
          href: "/",
        },
        {
          text: "Службы судебных приставов",
          active: true,
        },
      ],

      options: settings,
      bailiffsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_BAILIFFS_GET}`,
        insertUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_BAILIFFS_CREATE}`,
        updateUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_BAILIFFS_UPDATE}`,
        deleteUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_BAILIFFS_DELETE}`,
      }),
      templatesStore: createStoreExtend({
        key: "fileId",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_TEMPLATES_GET}?filter=['templateGroupId', '=' , 1]`,
      }),
    };
  },

  methods: {
    onTemplatesChanged(e, data) {
      data.setValue(e.value);
    },
  },
};
</script>

<style></style>
